















































import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import GeneralSettingsPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-general-settings-page-otel-logs.mixin';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import { IRentalCarVendorsPerCountryDiff } from '@/modules/cars/models/cars-comp-set.model';
import CompsetTableItem from '@/modules/cars/modules/settings/components/comp-set-table-item.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';

export interface ICompSetItem {
    key: string,
    country: string;
    dataSource: string;
    competitors: { name: string, value: string }[];
    isNew?: boolean;
}

@Component({
    components: {
        LoaderWrapper,
        CompsetTableItem,
        CustomLoader,
    },
})
export default class CompSetTabSettings extends GeneralSettingsPageOtelLogs {
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;

    static title = 'Comp. Set';

    protected newCompsets: ICompSetItem[] = [];
    protected oldCompsets: ICompSetItem[] = [];
    protected indexKey: number = 0;
    protected isLoading: boolean = false;

    created() {
        // Force loading of vendor list, it can be not called if we don't have countries or country providers in allowedVendorsPerCountry
        // eslint-disable-next-line no-unused-expressions
        this.carsSharedService.vendorsList;
        this.resetCurrentCompsets();
    }

    get compsets() {
        const data: ICompSetItem[] = [];

        this.newCompsets.forEach(item => {
            data.unshift(item);
        });
        this.oldCompsets.forEach(item => {
            data.push(item);
        });

        return data;
    }

    handleAddNewButton() {
        this.newCompsets.push({
            key: `new${this.indexKey++}`,
            country: '',
            dataSource: '',
            competitors: [],
            isNew: true,
        });
    }

    handleRemoveButton(item: ICompSetItem) {
        if (item.isNew) {
            this.newCompsets.splice(this.newCompsets.findIndex(newItem => newItem.key === item.key), 1);
        } else {
            this.isLoading = true;
            this.carsSharedService.removeCompSetSetting(item.country, item.dataSource).then(response => {
                if (response?.data) {
                    this.carsSharedService.resetAllowedVendorsPerCountryData(response.data?.data || {});
                    this.$nextTick(() => {
                        this.resetCurrentCompsets();
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    handleSaveButton() {
        if (this.compsets.some(item => (!item.country || !item.dataSource || !item.competitors.length))) {
            return;
        }

        const diff = {
            set: {},
            unset: [],
        } as IRentalCarVendorsPerCountryDiff;

        // Compose all items to set
        this.compsets.forEach(item => {
            if (!diff.set[item.country]) {
                diff.set[item.country] = {};
            }
            diff.set[item.country][item.dataSource] = item.competitors.map(comp => comp.value);
        });

        const uniqueCompetitors = (items: string[]) => JSON.stringify(items.sort());

        const { allowedVendorsPerCountry } = this.carsFiltersService;
        if (allowedVendorsPerCountry) {
            Object.keys(allowedVendorsPerCountry).forEach(country => {
                Object.keys(allowedVendorsPerCountry[country]).forEach(dataSource => {
                    // Unset existing items missing in current data
                    if (!diff.set[country]?.[dataSource]) {
                        diff.unset!.push({
                            countryName: country,
                            dataSource,
                        });
                    } else if (uniqueCompetitors(diff.set[country][dataSource]) === uniqueCompetitors(allowedVendorsPerCountry[country][dataSource])) {
                        // Don't set already existing items.
                        delete diff.set[country][dataSource];
                        if (!Object.keys(diff.set[country]).length) {
                            delete diff.set[country];
                        }
                    }
                });
            });
        }

        if (!Object.keys(diff.set).length && (diff.unset!.length)) {
            this.notifyService.warning('No changes found.', {
                showClose: true,
                duration: 3000,
            });
            return;
        }

        this.isLoading = true;
        this.carsSharedService.updateCompSetSettings(diff).then(response => {
            if (response?.data) {
                this.carsSharedService.resetAllowedVendorsPerCountryData(response.data?.data || {});
                this.$nextTick(() => {
                    this.newCompsets = [];
                    this.resetCurrentCompsets();
                });
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }

    get allowSave() {
        return !this.compsets.some(item => !item.country || !item.dataSource || !item.competitors.length);
    }

    resetCurrentCompsets() {
        this.oldCompsets = [];

        const { allowedVendorsPerCountry } = this.carsFiltersService;
        if (allowedVendorsPerCountry) {
            Object.keys(allowedVendorsPerCountry).forEach(country => {
                Object.keys(allowedVendorsPerCountry[country]).forEach(dataSource => {
                    this.oldCompsets.push({
                        key: `old${this.indexKey++}`,
                        country,
                        dataSource,
                        competitors: allowedVendorsPerCountry[country][dataSource].map(value => ({
                            name: String(value),
                            value: String(value),
                        })),
                    });
                });
            });
        }
    }
}
